import { useContext, useEffect, useRef, useState } from "react";
import { Button, ButtonGroup } from "../../../components/button/Button";
import styles from "./style.module.css";

import {
  Card,
  CardGroup,
  Container,
  ContentWrapper,
  Description,
  Footer,
  FooterText,
  Header,
  HeaderContent,
  HeaderDescription,
  ImageTitle,
  Label,
  MainContent,
  Section,
  Tag,
  TextBlock,
  Title,
  AddAttachmentWrapper,
  AddAttachmentText,
  PlusIcon,
} from "./Styled";
import { useParams } from "react-router-dom";
import { useCarePlan } from "../../../lib/hooks/useCarePlan";

import { Modal } from "../../../components/modal/Modal";
import { AlertContext, BaseContext } from "../../../lib/context/context";
import {
  CarePlanContent,
  PillarContentEntity,
  PublishModalTypes,
  CarePlanStatusModalProps,
  CarePlanMultipleAttachment,
  UploadedAttachment,
} from "../../../lib/interfaces/carePlan";
import { ExitCarePlan } from "../../../components/carePlan/ExitCarePlan";
import { CarePlanStatus } from "../../../components/carePlanStatus/CarePlanStatus";
import { Spinner } from "../../../components/spinner/Spinner";
import { Editor } from "../../../components/editor/Editor";
import { marked } from "marked";
import {
  careplanPatientDetailFormatDateTime,
  formatLastUpdatedDate,
} from "../../../lib/util/date";
import { CarePlanProps } from "../../../lib/interfaces/carePlan";
import { ConfirmPopup } from "../../../components/carePlan/ConfirmPopup";
import { logError } from "../../../lib/util/logger";
import PublishModal from "../../../components/carePlan/Modal/PublishModal";
import { AddAttachment } from "./AddAttachment";
import { uploadPillarAttachments } from "../../../lib/apis/carePlan";

interface PatientRouteParam {
  userId: string;
}

const initalModalValues: CarePlanStatusModalProps = {
  isVisible: false,
};

export const PillarEdit = ({
  patient,
  pillarDatas,
  onClose,
}: CarePlanProps) => {
  const { userId } = useParams<PatientRouteParam>();
  const { pushAlert } = useContext(AlertContext);
  const { updateCarePlan } = useCarePlan();

  const controller = new AbortController();
  const { selectedTemplateData } = useContext(BaseContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fileUploadSuccess, setFileUploadSuccess] = useState(false);
  const [showExitModal, setShowExitModal] = useState<boolean>(false);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [pillarDetails, setPillarDetails] =
    useState<PillarContentEntity | null>(null);

  const [attachmentData, setAttachmentData] = useState(
    selectedTemplateData?.content.carePlanContent[0].attachment || null
  );
  const [publishModal, setPublishModal] =
    useState<CarePlanStatusModalProps>(initalModalValues);

  const [attachmentComponents, setAttachmentComponents] = useState<number[]>(
    []
  );

  const [attachments, setAttachments] = useState<CarePlanMultipleAttachment[]>(
    []
  );

  const uploadedAttachmentsRef = useRef<UploadedAttachment[]>([]);

  useEffect(() => {
    return () => controller.abort();
  }, []);

  useEffect(() => {
    if (selectedTemplateData) {
      const carePlanData = selectedTemplateData?.content;

      if (
        carePlanData?.carePlanContent &&
        carePlanData?.carePlanContent?.length > 0
      ) {
        const updatePillarData = async () => {
          // Resolve the content body using marked
          const convertMarkToHTML = carePlanData?.carePlanContent[0]?.body
            ? await marked(carePlanData?.carePlanContent[0]?.body)
            : "";

          const summaryContentWithResolvedBody: CarePlanContent = {
            ...carePlanData?.carePlanContent[0],
            body: convertMarkToHTML,
          };

          setPillarDetails({
            ...selectedTemplateData,
            content: {
              ...carePlanData,
              carePlanContent: [summaryContentWithResolvedBody],
            },
          });
        };
        updatePillarData();
      }
    }
  }, [selectedTemplateData]);

  useEffect(() => {
    if (pillarDetails?.attachments && pillarDetails.attachments.length > 0) {
      // Initialize attachment components from pillarDetails
      const existingAttachments = pillarDetails.attachments.map(
        (att, index) => index
      );
      setAttachmentComponents(existingAttachments);

      // Initialize attachments state
      const attachmentsData = pillarDetails.attachments.map((att, index) => ({
        id: index,
        file: null,
        name: att.attachmentName || "",
        title: att.title || "",
        description: att.description || "",
        attachmentSignedUrl: att.attachmentSignedUrl || "",
      }));
      setAttachments(attachmentsData);

      // Initialize uploaded attachments ref
      uploadedAttachmentsRef.current = pillarDetails.attachments.map((att) => ({
        attachmentUrl: att.attachmentUrl,
        attachmentName: att.attachmentName,
        title: att.title || "",
        description: att.description || "",
      }));
    }
  }, [pillarDetails?.attachments]);

  const handleContentChange = (
    field: keyof CarePlanContent,
    value: string | object
  ): void => {
    setPillarDetails((prevState: PillarContentEntity | null) => {
      if (!prevState) return prevState;

      const { content } = prevState;
      const { carePlanContent } = content;

      // Ensure carePlanContent exists and has at least one item
      if (!Array.isArray(carePlanContent) || carePlanContent.length === 0) {
        return prevState;
      }

      // Update the first CarePlanContent item immutably
      const updatedCarePlanContent = {
        ...carePlanContent[0],
        [field]: value,
      };

      return {
        ...prevState,
        content: {
          ...content,
          carePlanContent: [updatedCarePlanContent],
        },
      };
    });
  };

  const updateCarePlanHandler = async (
    shouldRedirect: boolean = true
  ): Promise<void> => {
    if (
      !pillarDetails ||
      !pillarDetails.pillarId ||
      !pillarDetails.careplanId ||
      !pillarDetails.patientGroupTypeId
    ) {
      pushAlert("Care Plan details are incomplete.", "warning");
      return;
    }

    const { pillarId, careplanId, patientGroupTypeId, content } = pillarDetails;
    const attachments = uploadedAttachmentsRef.current;

    // Ensure userId is available
    if (!userId) {
      pushAlert("User ID is missing.", "danger");
      return;
    }

    try {
      const res = await updateCarePlan(
        userId,
        pillarId,
        patientGroupTypeId,
        careplanId,
        content,
        attachments as []
      );

      if (res && typeof res === "object") {
        if (shouldRedirect) {
          onClose();
          setAttachments([]);
        }

        setFileUploadSuccess(false);
        if (!shouldRedirect) {
          setPublishModal({
            isVisible: true,
            modalType: PublishModalTypes.Published,
          });
        } else {
          pushAlert("Care Plan updated successfully.", "success");
        }
      } else {
        pushAlert("Unexpected response from the server.", "danger");
      }
    } catch (error) {
      logError(
        "Error updating Care Plan: ",
        { userId, pillarId, patientGroupTypeId, careplanId, content },
        error as Error
      );
    }
  };

  const [scrolled, setScrolled] = useState(false);
  const contentWrapperRef = useRef<HTMLDivElement>(null);
  const isRedirectRef = useRef(false);

  const handleFileUpload = async () => {
    try {
      const formData = new FormData();

      const validAttachments = attachments.filter((file) => file.file !== null);

      validAttachments.forEach((file, index) => {
        const timestamp = Date.now();
        const uniqueName = `${userId}_${timestamp}`;
        formData.append("attachments", file.file as File, uniqueName);
      });
      formData.append("userId", userId);

      const result = await uploadPillarAttachments(formData);

      if (result?.status === 200 && result.data?.attachments) {
        const updatedAttachments = [...uploadedAttachmentsRef.current];

        validAttachments.forEach((attachment, index) => {
          const apiAttachment = result.data.attachments[index];
          const existingIndex = attachmentComponents.find(
            (i) => i === attachment.id
          );

          const newAttachment = {
            attachmentUrl: apiAttachment.attachmentUrl,
            attachmentName: attachment.name,
            title: attachment.title,
            description: attachment.description,
          };

          if (existingIndex !== undefined) {
            updatedAttachments[existingIndex] = newAttachment;
          } else {
            updatedAttachments.push(newAttachment);
          }
        });

        uploadedAttachmentsRef.current = updatedAttachments;

        pushAlert("File added successfully", "success");
        setFileUploadSuccess(true);
        return true;
      } else {
        pushAlert("File unable to be uploaded. Please try again.", "danger");
        return false;
      }
    } catch (error) {
      logError("Error during file upload:", { attachments }, error as Error);
      return false;
    }
  };

  useEffect(() => {
    if (fileUploadSuccess) {
      const handleSuccess = async () => {
        try {
          await updateCarePlanHandler(isRedirectRef.current);

          // Only show publish modal if not redirecting
          if (!isRedirectRef.current) {
            setPublishModal({
              isVisible: true,
              modalType: PublishModalTypes.Published,
            });
          }
        } catch (error) {
          pushAlert("Failed to update Care Plan. Please try again.", "danger");
          logError("Error during updating Care Plan:", {}, error as Error);
        }
      };

      handleSuccess();
    }
  }, [fileUploadSuccess]);

  const handleSave = async (shouldRedirect: boolean = true) => {
    const carePlanContent = selectedTemplateData?.content.carePlanContent[0];
    const title = carePlanContent?.title;
    const body = carePlanContent?.body;
    isRedirectRef.current = shouldRedirect;

    if (!title || !body) {
      return;
    }

    try {
      const hasAttachments = attachments.some(
        (attachment) => attachment.file !== null
      );

      if (hasAttachments) {
        await handleFileUpload();
      } else {
        await updateCarePlanHandler(shouldRedirect);
      }
    } catch (error) {
      pushAlert("Failed to save Care Plan. Please try again.", "danger");
      logError(
        "Error to save Care Plan:",
        { carePlanContent, title, body, attachments },
        error as Error
      );
    }
  };

  const removeAttachment = () => {
    setPillarDetails((prev) => {
      if (!prev) return prev;

      const updatedCarePlanContent = prev.content.carePlanContent.map(
        (item, index) => {
          if (index === 0 && item.attachment) {
            const { attachment, ...rest } = item;
            return rest;
          }
          return item;
        }
      );

      return {
        ...prev,
        content: {
          ...prev.content,
          carePlanContent: updatedCarePlanContent,
        },
      };
    });
  };

  const handleCloseModal = () => {
    setPublishModal(initalModalValues);
  };

  const handleAddAttachmentComponent = () => {
    setShowValidationErrors(false);

    const newId = attachmentComponents.length;
    setAttachmentComponents((prev) => [...prev, newId]);

    setAttachments((prev) => [
      ...prev,
      { id: newId, file: null, name: "", title: "", description: "" },
    ]);

    uploadedAttachmentsRef.current = [
      ...uploadedAttachmentsRef.current,
      {
        attachmentUrl: "",
        attachmentName: "",
        title: "",
        description: "",
      },
    ];

    // Reset errors for the new attachment
    setAttachmentFieldErrors((prev) => {
      const newErrors = [...prev];
      newErrors[newId] = {};
      return newErrors;
    });
  };

  const handleRemoveAttachmentComponent = (indexToRemove: number) => {
    // Remove the component from attachmentComponents and update indices
    setAttachmentComponents((prev) => {
      const filtered = prev.filter((_, index) => index !== indexToRemove);
      return filtered.map((_, index) => index);
    });

    // Update attachments
    setAttachments((prev) => {
      const filtered = prev.filter((_, index) => index !== indexToRemove);
      return filtered.map((item, index) => ({ ...item, id: index }));
    });

    // Update attachment field errors - only remove the errors for the removed index
    setAttachmentFieldErrors((prev) => {
      const newErrors = [...prev];
      newErrors.splice(indexToRemove, 1);
      return newErrors;
    });

    uploadedAttachmentsRef.current = uploadedAttachmentsRef.current
      .filter((_, index) => index !== indexToRemove)
      .map((att, index) => ({ ...att }));
  };

  const handleAttachmentFileChange = (
    id: number,
    file: File | null,
    name: string
  ) => {
    setAttachments((prev) =>
      prev.map((att) => (att.id === id ? { ...att, file, name } : att))
    );
  };

  const handleTitleChange = (id: number, title: string) => {
    setAttachments((prev) =>
      prev.map((att) => (att.id === id ? { ...att, title } : att))
    );

    // Update uploadedAttachmentsRef if it exists
    if (uploadedAttachmentsRef.current[id]) {
      uploadedAttachmentsRef.current = uploadedAttachmentsRef.current.map(
        (att, index) => (index === id ? { ...att, title } : att)
      );
    }
  };

  const handleDescriptionChange = (id: number, description: string) => {
    setAttachments((prev) =>
      prev.map((att) => (att.id === id ? { ...att, description } : att))
    );

    // Update uploadedAttachmentsRef if it exists
    if (uploadedAttachmentsRef.current[id]) {
      uploadedAttachmentsRef.current = uploadedAttachmentsRef.current.map(
        (att, index) => (index === id ? { ...att, description } : att)
      );
    }
  };

  const attachmentToRemoveRef = useRef<number | null>(null);

  const hasChanges = () => {
    const hasBody = pillarDetails?.content.carePlanContent[0]?.body;

    return hasBody;
  };

  const [showIncompleteModal, setShowIncompleteModal] =
    useState<boolean>(false);

  const [attachmentFieldErrors, setAttachmentFieldErrors] = useState<
    Array<{ title?: boolean | string; description?: boolean; file?: boolean }>
  >([]);

  const [showValidationErrors, setShowValidationErrors] = useState(false);

  const validateAttachments = () => {
    let isValid = true;
    const newFieldErrors: Array<{
      title?: boolean | string;
      description?: boolean;
      file?: boolean;
    }> = new Array(attachments.length).fill({});

    attachments.forEach((att, index) => {
      const hasFile = att.name !== "";
      const hasTitle = att.title.trim() !== "";
      const hasDescription = att.description.trim() !== "";
      const isTitleTooLong = att.title.length > 100;

      const errors: {
        title?: boolean | string;
        description?: boolean;
        file?: boolean;
      } = {};

      if (!hasTitle) {
        errors.title = true;
        isValid = false;
      } else if (isTitleTooLong) {
        errors.title = "Title must be 100 characters or less";
        isValid = false;
      }

      if (!hasDescription) {
        errors.description = true;
        isValid = false;
      }

      if (!hasFile) {
        errors.file = true;
        isValid = false;
      }

      newFieldErrors[index] = errors;
    });

    setAttachmentFieldErrors(newFieldErrors);
    return isValid;
  };

  useEffect(() => {
    if (showValidationErrors) {
      validateAttachments();
    }
  }, [attachments, showValidationErrors]);

  const [isSaving, setIsSaving] = useState(false);

  const handleSaveClick = async (shouldRedirect: boolean = true) => {
    setShowValidationErrors(true);
    const isValid = validateAttachments();

    if (!isValid) {
      setShowExitModal(false);
      setShowIncompleteModal(true);
    } else {
      setIsSaving(true);
      try {
        await handleSave(shouldRedirect);
        setShowValidationErrors(false);
        setAttachmentFieldErrors(attachments.map(() => ({})));
      } catch (error) {
        logError(
          "Error in handleSaveClick:",
          { shouldRedirect, attachments, pillarDetails },
          error as Error
        );
        pushAlert("Failed to save Care Plan. Please try again.", "danger");
      } finally {
        setIsSaving(false);
      }
    }
  };

  // Add a cleanup effect to reset validation state when component unmounts
  useEffect(() => {
    return () => {
      setShowValidationErrors(false);
      setAttachmentFieldErrors([]);
    };
  }, []);

  const handlePublishClick = async () => {
    setShowValidationErrors(true);
    const isValid = validateAttachments();

    if (!isValid) {
      setShowIncompleteModal(true);
      return;
    }

    setIsSaving(true);
    try {
      // Check if there are any attachments with files
      const hasAttachments = attachments.some(
        (attachment) => attachment.file !== null
      );

      if (hasAttachments) {
        // If we have attachments, upload them first
        const uploadSuccess = await handleFileUpload();
        if (!uploadSuccess) {
          pushAlert(
            "Failed to upload attachments. Please try again.",
            "danger"
          );
          return;
        }
      } else {
        // If no attachments, directly update care plan and show publish modal
        await updateCarePlanHandler(false);
        setPublishModal({
          isVisible: true,
          modalType: PublishModalTypes.Published,
        });
      }
    } catch (error) {
      logError(
        "Error to publish Care Plan:",
        { attachments, pillarDetails },
        error as Error
      );
      pushAlert("Failed to publish Care Plan. Please try again.", "danger");
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <>
      {showExitModal && (
        <Modal
          visible={showExitModal}
          onCloseModal={() => {}}
          title="Save changes before exiting?"
          dismissable={false}
          className={styles.modalExit}
        >
          <ExitCarePlan
            onSubmit={() => handleSaveClick(true)}
            exitWithOutSave={() => {
              setShowExitModal(false);
              onClose();
            }}
            onCancel={() => setShowExitModal(false)}
            isLoading={isLoading}
            disabled={!hasChanges()}
          />
        </Modal>
      )}

      {showConfirmModal && (
        <Modal
          visible={showConfirmModal}
          onCloseModal={() => {
            setShowConfirmModal(false);
            attachmentToRemoveRef.current = null;
          }}
          title="Remove attachment"
          dismissable={true}
          className={styles.modalExit}
        >
          <ConfirmPopup
            message="Would you like to remove this attachment from the Care Plan?"
            onSubmit={() => {
              if (attachmentToRemoveRef.current !== null) {
                handleRemoveAttachmentComponent(attachmentToRemoveRef.current);
              }
              setShowConfirmModal(false);
              attachmentToRemoveRef.current = null;
              if (attachmentData !== null) {
                setAttachmentData(null);
                removeAttachment();
              }
            }}
            onCancel={() => {
              setShowConfirmModal(false);
              attachmentToRemoveRef.current = null;
            }}
          />
        </Modal>
      )}

      {showIncompleteModal && (
        <Modal
          visible={showIncompleteModal}
          onCloseModal={() => {
            setShowIncompleteModal(false);
          }}
          title="Incomplete Attachment Module"
          dismissable={true}
          className={styles.modalExit}
        >
          <ConfirmPopup
            message="Attachment modules must be completely empty or completely filled to be accepted for Exiting, Saving or Publishing. Please empty or fill module(s) to continue."
            onSubmit={() => setShowIncompleteModal(false)}
            onCancel={() => setShowIncompleteModal(false)}
            showButtons={false}
          />
        </Modal>
      )}

      <PublishModal
        isVisible={publishModal.isVisible}
        modalType={publishModal.modalType}
        onClose={handleCloseModal}
        patient={patient}
        carePlanDetail={selectedTemplateData || null}
      />

      {isLoading ? (
        <div className={styles.spinner}>
          <Spinner />
        </div>
      ) : (
        <>
          <Container>
            <Header>
              <HeaderContent>
                <HeaderDescription scrolled={scrolled}>
                  {selectedTemplateData?.status && (
                    <Tag>
                      <CarePlanStatus status={selectedTemplateData?.status} />
                    </Tag>
                  )}

                  <HeaderDescription scrolled={scrolled}>
                    <Title scrolled={scrolled}>
                      {`${selectedTemplateData?.pillarName} : ${selectedTemplateData?.patientGroupTypeName}`}
                    </Title>
                    {patient?.name && (
                      <Description>
                        {`${patient.name}`} • {patient.assignedSex} •{" "}
                        {patient?.dateOfBirth &&
                          careplanPatientDetailFormatDateTime(
                            patient?.dateOfBirth
                          )}{" "}
                        {patient?.mrnId && <> • {patient?.mrnId}</>}
                      </Description>
                    )}
                  </HeaderDescription>
                </HeaderDescription>
              </HeaderContent>
            </Header>
          </Container>

          <ContentWrapper ref={contentWrapperRef}>
            <MainContent>
              <CardGroup>
                <Label>Pillar Image</Label>
                <Card>
                  <table width="100%">
                    <tr>
                      <td width="220px">
                        <img
                          src={selectedTemplateData?.mediaUrl}
                          alt="Pillar Image"
                        />
                      </td>
                      <td valign="middle">
                        <ImageTitle>
                          {selectedTemplateData?.mediaUrl?.split("/").pop()}
                        </ImageTitle>
                      </td>
                    </tr>
                  </table>
                </Card>
                <Section>
                  <TextBlock>
                    <Label>Intro Text Block</Label>

                    <Editor
                      key={0}
                      value={
                        pillarDetails?.content?.carePlanContent[0]?.body || ""
                      }
                      onChange={(value) => {
                        handleContentChange("body", value);
                      }}
                    />
                  </TextBlock>
                </Section>
              </CardGroup>
            </MainContent>

            {attachmentComponents.map((index) => (
              <AddAttachment
                key={index}
                onRemove={() => {
                  attachmentToRemoveRef.current = index;
                  setShowConfirmModal(true);
                }}
                onFileChange={(file, name) =>
                  handleAttachmentFileChange(index, file, name)
                }
                onTitleChange={(title) => handleTitleChange(index, title)}
                onDescriptionChange={(description) =>
                  handleDescriptionChange(index, description)
                }
                fileData={attachments.find((item) => item.id === index)}
                title={attachments.find((att) => att.id === index)?.title || ""}
                description={
                  attachments.find((att) => att.id === index)?.description || ""
                }
                fieldErrors={{
                  ...attachmentFieldErrors[index],
                  file: attachmentFieldErrors[index]?.file,
                }}
                attachmentSignedUrl={
                  attachments.find((att) => att.id === index)
                    ?.attachmentSignedUrl
                }
              />
            ))}

            <MainContent>
              <CardGroup padding="16px" hoverColor="#ECF5F9">
                <AddAttachmentWrapper onClick={handleAddAttachmentComponent}>
                  <PlusIcon size={16} weight="bold" />
                  <AddAttachmentText>Add Attachment Module</AddAttachmentText>
                </AddAttachmentWrapper>
              </CardGroup>
            </MainContent>
            <br />

            <Footer>
              <Button
                size="medium"
                type="secondary"
                label="Exit"
                iconPosition="left"
                onClick={() => setShowExitModal(true)}
                className={styles.button}
              />
              <ButtonGroup>
                <>
                  {selectedTemplateData && selectedTemplateData?.updatedAt && (
                    <FooterText>
                      Last saved{" "}
                      {formatLastUpdatedDate(selectedTemplateData.updatedAt)}
                    </FooterText>
                  )}
                </>
                <Button
                  size="medium"
                  type="secondary"
                  label={`Save & Do Not ${
                    selectedTemplateData?.status === "draft" ||
                    selectedTemplateData?.status === "changed"
                      ? "Publish"
                      : "Unpublish"
                  }`}
                  iconPosition="left"
                  onClick={() => handleSaveClick(true)}
                  disabled={!hasChanges() || isSaving}
                  className={styles.button}
                />
                {selectedTemplateData?.status === "draft" ||
                selectedTemplateData?.status === "changed" ? (
                  <Button
                    size="medium"
                    label="Publish..."
                    iconPosition="left"
                    onClick={handlePublishClick}
                    disabled={!hasChanges() || isSaving}
                    className={styles.button}
                  />
                ) : (
                  <Button
                    size="medium"
                    label="Unpublish..."
                    iconPosition="left"
                    onClick={() =>
                      setPublishModal({
                        isVisible: true,
                        modalType: PublishModalTypes.Unpublish,
                      })
                    }
                    disabled={isSaving}
                    className={styles.button}
                  />
                )}
              </ButtonGroup>
            </Footer>
          </ContentWrapper>
        </>
      )}
    </>
  );
};
