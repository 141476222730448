import { Tag } from "../tag/Tag";
import { Body } from "../typography/body/Body";
import { Heading } from "../typography/heading/Heading";
import { CalendarBlank, CalendarX, Check, Prohibit } from "phosphor-react";
import { Button, ButtonGroup } from "../button/Button";
import {
  PatientCardInfo,
  PatientInfo,
  ProviderInfo,
} from "../../lib/interfaces/user";
import { useHistory } from "react-router-dom";
import { Drawer } from "../drawer/Drawer";
import { useEffect, useState } from "react";
import { NewAppointment } from "../../containers/schedule/newAppointment/NewAppointment";
import { useProvider } from "../../lib/hooks/useProvider";
import { Modal } from "../modal/Modal";
import { NotificationType } from "../alertBanner/AlertBanner";
import styles from "./style.module.css";
import { AvatarLabelGroup } from "../avatarLabelGroup/AvatarLabelGroup";
import { UserDetails } from "../userDetails/UserDetails";
import { AppointmentType } from "../../lib/apis/types/visitReason.types";
import { Visit } from "../../lib/interfaces/visits";
import { JoinMeetingButton } from "../joinMeetingButton/JoinMeetingButton";
import { saveVideoStatus } from "../../lib/apis/availabilityManagement";
import { useBaseContext } from "../../containers/_base/Base";

export interface AppointmentDetailCardProps {
  date: string;
  patient: PatientInfo | PatientCardInfo;
  preVisitInfoComplete?: boolean;
  visit: Visit;
  beforeAppt?: boolean;
  onClickCancelAppointment: () => void;
  onAppointmentUpdated?: (
    close: boolean,
    alertMessage?: string,
    alertType?: NotificationType
  ) => void;
}

export const AppointmentDetailCard = ({
  date,
  patient,
  preVisitInfoComplete,
  visit,
  beforeAppt,
  onAppointmentUpdated,
  onClickCancelAppointment,
}: AppointmentDetailCardProps) => {
  const history = useHistory();
  const { getProvider } = useProvider();
  const [chosenProvider, setChosenProvider] = useState<ProviderInfo>();
  const [viewUpdateApptDrawer, setViewUpdateApptDrawer] = useState(false);
  const [cancelModalVisible, setCancelModalVisible] = useState<boolean>(false);
  const [chosenVisitTypeId, setChosenVisitTypeId] = useState<string>();
  const [chosenPatient, setChosenPatient] = useState<
    PatientInfo | PatientCardInfo
  >();
  const [showProviderModal, setShowProviderModal] = useState<boolean>(false);
  //bookingAnother is used to handle the case where a user reschedules an appt, then chooses book another, but then closes the drawer before booking another appt
  const [bookingAnother, setBookingAnother] = useState<boolean>(false);
  const { userInfo } = useBaseContext();
  const { id: userId } = userInfo ?? {};

  const patientInfo = {
    email: patient?.email,
    mobile: patient?.mobile,
    name: patient?.name,
    userId: patient?.userId,
  };

  const {
    visitId,
    providerId,
    providerName,
    visitType,
    appointmentType,
    checkedIn,
  } = visit;

  useEffect(() => {
    if (providerId && providerId !== "")
      getProvider(providerId).then(setChosenProvider);
  }, [providerId]);

  useEffect(() => {
    if (chosenPatient && chosenVisitTypeId && chosenProvider) {
      setViewUpdateApptDrawer(true);
    }
  }, [chosenPatient, chosenVisitTypeId, chosenProvider]);

  const handleUpdateAppointmentExit = () => {
    if (bookingAnother) onAppointmentUpdated && onAppointmentUpdated(true);
    setViewUpdateApptDrawer(false);
    setChosenPatient(undefined);
    setChosenVisitTypeId(undefined);
    setChosenProvider(undefined);
  };

  const handleUpdateAppointmentClick = () => {
    setChosenPatient(patient);
    setChosenVisitTypeId(visit.visitTypeId);
    providerId && getProvider(providerId).then(setChosenProvider);
  };

  const handleCancelApptClick = () => {
    setCancelModalVisible(true);
  };

  const handleCancelApptConfirm = async () => {
    await onClickCancelAppointment();
    setCancelModalVisible(false);
  };

  const handleAppointmentRescheduled = (bookAnother?: boolean) => {
    bookAnother && setBookingAnother(bookAnother);
    !bookAnother && setViewUpdateApptDrawer(false);
    if (onAppointmentUpdated) {
      !bookAnother &&
        onAppointmentUpdated(
          true,
          "Appointment successfuly rescheduled.",
          "success"
        );
      bookAnother && onAppointmentUpdated(false);
    }
  };

  const titleOverride: JSX.Element = (
    <>
      <div style={{ display: "flex" }}>
        <Body size="sm" color="secondary">
          {visitType} with{" "}
        </Body>
        <div
          data-dd-privacy="mask"
          onClick={() => {
            setShowProviderModal(true);
          }}
          style={{ cursor: "pointer" }}
        >
          <Body size="sm" color="link">
            {providerName}
          </Body>
        </div>
      </div>
      <Body size="sm" weight="bold" color="secondary">
        {`${appointmentType}`} Visit
      </Body>
    </>
  );

  return (
    <div className={styles.appointmentDetailCard}>
      <div className={styles.row1}>
        <Heading type="03" style={{ width: 417, height: 22, paddingRight: 14 }}>
          {date}
        </Heading>
        <ButtonGroup>
          <Button
            size="small"
            type="secondary-gray"
            disabled={!beforeAppt}
            label="Cancel"
            iconPosition="left"
            Icon={CalendarX}
            onClick={handleCancelApptClick}
          />
          <Button
            size="small"
            type="secondary-gray"
            disabled={!beforeAppt}
            label="Reschedule"
            Icon={CalendarBlank}
            iconPosition="left"
            onClick={handleUpdateAppointmentClick}
          />

          {appointmentType === AppointmentType.Virtual ? (
            <JoinMeetingButton
              visitDateTime={visit.dateTime}
              handleClick={() => {
                saveVideoStatus();
                sessionStorage.setItem(
                  "patientData",
                  JSON.stringify(patientInfo)
                );
                history.push(`/video-chat/${visitId}/${userId}`);
              }}
            />
          ) : (
            <></>
          )}
        </ButtonGroup>
      </div>
      <div className={styles.row2}>
        <AvatarLabelGroup
          users={[patient]}
          titleOverride={titleOverride}
          linkUser={true}
        />
        <div className={styles.apptTags}>
          <div className={styles.tag}>
            <Tag
              content={"Checked In"}
              type={checkedIn ? "success" : "danger"}
              Icon={checkedIn ? Check : Prohibit}
              iconPosition={"right"}
            />
          </div>

          <Tag
            content={"Pre Visit Info Complete"}
            type={preVisitInfoComplete ? "success" : "danger"}
            Icon={preVisitInfoComplete ? Check : Prohibit}
            iconPosition={"right"}
          />
        </div>
      </div>

      <Drawer
        title={"Update Appointment"}
        onClose={() => {
          handleUpdateAppointmentExit();
        }}
        visible={viewUpdateApptDrawer}
        className={styles.drawer}
      >
        {chosenVisitTypeId && (
          <NewAppointment
            data-dd-privacy="mask"
            visitId={visitId as string}
            chosenPatient={chosenPatient}
            chosenVisitTypeId={chosenVisitTypeId}
            chosenProvider={chosenProvider}
            onNewAppointmentBooked={handleAppointmentRescheduled}
            disableVisitField={true}
            visit={visit}
          />
        )}
      </Drawer>

      <Modal
        onCloseModal={() => {}}
        title="Cancel Appointment"
        dismissable={false}
        visible={cancelModalVisible}
      >
        <div style={{ overflow: "auto" }}>
          <Body>
            Are you sure you want to cancel this appointment? The time will be
            released for another patient.
          </Body>

          <div style={{ height: 32 }} />

          <ButtonGroup className={styles.buttons} align={"end"}>
            <Button
              onClick={() => setCancelModalVisible(false)}
              type="secondary-gray"
              label="Cancel"
            />
            <Button
              onClick={handleCancelApptConfirm}
              label="Cancel Appointment"
            />
          </ButtonGroup>
        </div>
      </Modal>

      <Modal
        onCloseModal={() => setShowProviderModal(false)}
        visible={showProviderModal}
        title={"Provider Detail"}
        centerTitle={true}
        className={styles.providerModal}
      >
        <UserDetails data-dd-privacy="mask" provider={chosenProvider} />
      </Modal>
    </div>
  );
};
